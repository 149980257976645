const zynity_api_version = process.env.REACT_APP_ZYNITY_API_VERSION;
const zynity_api_url = `${process.env.REACT_APP_ZYNITY_API_URL}/${zynity_api_version}`;
const yii_website_url = process.env.REACT_APP_ZYNITY_YII_URL;

export const zynity_api = {
    url: zynity_api_url,
    download_file: `${zynity_api_url}/download-file?path=`,
    retrieve_authenticated_user: `${zynity_api_url}/authenticated-user`,
    global_upload_temp_files: `${zynity_api_url}/upload-file`,
    global_download_files: `${zynity_api_url}/download-file`,
    get_user_organization: `${zynity_api_url}/users/{user_id}/organizations`,
    get_user_organization_light: `${zynity_api_url}/users/{user_id}/organizations-light`,
    user_organization_team_z_mail: `${zynity_api_url}/users/{user_id}/organizations/{organization_id}/teams/{team_id}/z_mails/{z_mail_id}`,
    user_organization_team_z_mail_post: `${zynity_api_url}/users/{user_id}/organizations/{organization_id}/teams/{team_id}/z_mails`,
    user_organization_team_set_z_mail_as_viewed: `${zynity_api_url}/users/{user_id}/organizations/{organization_id}/teams/{team_id}/mark_z_mail_as_viewed`,
    user_update_profile: `${zynity_api_url}/users/{user_id}/update_profile`,
    admin_get_counts: `${zynity_api_url}/admin/get-counts`,
    admin_editor_content: `${zynity_api_url}/admin/editor-contents`,
    admin_users: `${zynity_api_url}/admin/users`,
    admin_services: `${zynity_api_url}/admin/services`,
    admin_organizations: `${zynity_api_url}/admin/organizations`,
    admin_coaches: `${zynity_api_url}/admin/coaches`,
    admin_assessments: `${zynity_api_url}/admin/assessments`,
    coaches_organizations: `${zynity_api_url}/coaches/{coach_id}/organizations`,
    coach_notes_by_team: `${zynity_api_url}/coaches/{coach_id}/teams/{team_id}/coach_notes`,
    users_assessments_team: `${zynity_api_url}/users/{user_id}/assessments/team`,
    users_assessments_today: `${zynity_api_url}/users/{user_id}/assessments/today`,
    users_assessments_tomorrow: `${zynity_api_url}/users/{user_id}/assessments/tomorrow`,
    assessments: `${zynity_api_url}/assessments`,
    users_how_lead: `${zynity_api_url}/users/{user_id}/how_lead`,
    organization_members: `${zynity_api_url}/organizations/{organization_id}/users`,
    organization_teams: `${zynity_api_url}/organizations/{organization_id}/teams`,
    organization_team: `${zynity_api_url}/organizations/{organization_id}/teams/{team_id}`,
    organization_coaches: `${zynity_api_url}/organizations/{organization_id}/coaches`,
    organization_administrators: `${zynity_api_url}/organizations/{organization_id}/administrators`,
    organizations: `${zynity_api_url}/organizations`,
    add_coach_note: `${zynity_api_url}/coaches/{coach_id}/teams/{team_id}/coach_notes`,
}

export const zynity_website = {
    public: 'https://zynity.com',
    apps_site: 'https://apps.zynity.com',
    wiki: 'https://wiki.zynity.com',
    yii_website_url: yii_website_url,
    yii_website_logout_url: `${yii_website_url}/site/logout`
}
