import React, {useEffect, useState} from 'react'
import {Header} from "../../header/Header";
import DataTable from "react-data-table-component";
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import FilterComponent from "../../ui/FilterComponent";
import {ActionDropdown} from "../ActionDropdown";

export const OrganizationsManage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [filterText, setFilterText] = React.useState(null);

  const fetchData = async page => {
    setLoading(true);
    setCurrentPage(page);
    let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
    const response = await axios.get(`${zynity_api.admin_organizations}?page=${page}&limit=${perPage}${filter}`);

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setCurrentPage(page);
    let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
    const response = await axios.get(`${zynity_api.admin_organizations}?page=${page}&limit=${newPerPage}${filter}`);

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      setFilterText('');
    };

    const handleFilter = (e) => {
      setFilterText(e.target.value)
    }

    return (
      <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      maxWidth: '10px'
    },
    {
      name: 'date',
      selector: row => row.date,
      maxWidth: '200px'
    },
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
      name: 'Email',
      selector: row => row.email,
      maxWidth: '200px'
    },
    {
      name: 'Url',
      selector: row => row.url,
      maxWidth: '200px'
    },
    {
      name: 'State',
      selector: row => row.state,
      maxWidth: '10px'
    },
    {
      name: 'Status',
      selector: row => row.status,
      maxWidth: '10px'
    },
    {
      name: 'Actions',
      cell: row => <ActionDropdown
        linkView={`/admin/organizations/${row.id}`}
        linkEdit={`/admin/organizations-update/${row.id}`}
        linkDelete={`${zynity_api.admin_organizations}/${row.id}`}
        messageBeforeToDelete={`Do you want to delete the Organization: ${row.name} (ID: ${row.id})?`}
        fetchData={fetchData}
        currentPage={currentPage} />,
      allowOverflow: true,
      button: true

    }
  ];

  useEffect(() => {
    fetchData(1)
  }, []);

  useEffect(() => {
    if(filterText !== null) {
      const timeOutId = setTimeout(() => fetchData(1), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [filterText]);

  return (
    <>
      <Header/>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 user-main-tittle">
            <h4>
              Organizations Manage
            </h4>
          </div>
          <div className="col-12 col-md-6 mt-0 mt-md-3 ">
            <a href="/admin/z-admin">Admin Home</a> &nbsp; &nbsp; 
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationPerPage={perPage}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          </div>
        </div>
      </div>
    </>
  )
}
