import React, {useEffect, useState} from 'react'
import {Header} from "../../../header/Header";
import {OrganizationAdminHeader} from "../OrganizationAdminHeader";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {FormOrganizationDetails} from "../../../admin/organizations/details/FormOrganizationDetails";
import {useForm} from "../../../hooks/useForm";
import axios from "axios";
import Swal from "sweetalert2";
import {zynity_api} from "../../../../config/external-routes";
import {Loading} from "../../../ui/loading/Loading";
import {ImageUploader} from "../../../ui/uploader/image/ImageUploader";
import {publicUrl} from "../../../../helpers/files";

export const OrganizationProfile = () => {
  const {organization_id} = useParams();
  const authenticated_user = useSelector((state:any) => state.auth)
  const user_organizations_obj = useSelector((state:any) => state.user_organizations);
  let user_organizations = user_organizations_obj.data?.data
  let organization = user_organizations.filter((item:any) => item.id == organization_id)[0];
  const [data, setFormDatum, setData] = useForm(organization)

  const [tempOrgImg, setTempOrgImg] = useState(null)

  const handleSubmitForm = (event:any) => {
    event.preventDefault();

    console.log("Going to validate")
    let form = document.getElementById('dataFormDetails');
    if (!form.checkValidity()) {
      console.log("Invalid Form")
      form.classList.add('was-validated');
      return false;
    }

    axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}
    axios.put(`${zynity_api.admin_organizations}/${organization_id}`, data)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
        })
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'We have problems updating the Organization!',
        })
      })
  }

  useEffect(() => {
    setData({
      ...data,
      ['image']: tempOrgImg
    })
  }, [tempOrgImg])

  let formParsedValues = {
    "name": {
      "value": data.name,
      "type": "text",
      "name": "name",
      "human_name": "Org Name",
      "required": true,
      "invalid_feedback": "Please add the organization name",
    },
    "url": {
      "value": data.url,
      "type": "text",
      "name": "url",
      "human_name": "Web Address",
      "required": false,
      "invalid_feedback": null
    },
    "contact": {
      "value": data.contact,
      "type": "text",
      "name": "contact",
      "human_name": "Contact",
      "required": false,
      "invalid_feedback": null
    },
    "email": {
      "value": data.email,
      "type": "email",
      "name": "email",
      "human_name": "Email",
      "required": false,
      "invalid_feedback": null
    },
    "address": {
      "value": data.address,
      "type": "text",
      "name": "address",
      "human_name": "Address",
      "required": false,
      "invalid_feedback": null
    },
    "address2": {
      "value": data.address2,
      "type": "text",
      "name": "address2",
      "human_name": "Address 2",
      "required": false,
      "invalid_feedback": null
    },
    "city": {
      "value": data.city,
      "type": "text",
      "name": "city",
      "human_name": "City",
      "required": false,
      "invalid_feedback": null
    },
    "state": {
      "value": data.state,
      "type": "text",
      "name": "state",
      "human_name": "State",
      "required": false,
      "invalid_feedback": null
    },
    "zip": {
      "value": data.zip,
      "type": "text",
      "name": "zip",
      "human_name": "Zip",
      "required": false,
      "invalid_feedback": null
    },
    "country": {
      "value": data.country,
      "type": "text",
      "name": "country",
      "human_name": "Country",
      "required": false,
      "invalid_feedback": null
    },
    "phone": {
      "value": data.phone,
      "type": "tel",
      "name": "phone",
      "human_name": "Phone",
      "required": false,
      "invalid_feedback": null
    },
    "phone2": {
      "value": data.phone2,
      "type": "tel",
      "name": "phone2",
      "human_name": "Phone 2",
      "required": false,
      "invalid_feedback": null
    },
    "vision": {
      "value": data.vision,
      "type": "textarea",
      "name": "vision",
      "human_name": "Vision",
      "required": false,
      "invalid_feedback": null
    },
    "mission": {
      "value": data.mission,
      "type": "textarea",
      "name": "mission",
      "human_name": "Mission",
      "required": false,
      "invalid_feedback": null
    },
    "coreValues": {
      "value": data.coreValues,
      "type": "textarea",
      "name": "coreValues",
      "human_name": "Core Values",
      "required": false,
      "invalid_feedback": null
    },
    "cultureStatement": {
      "value": data.cultureStatement,
      "type": "textarea",
      "name": "cultureStatement",
      "human_name": "Culture Statement",
      "required": false,
      "invalid_feedback": null
    },
    "notes": {
      "value": data.notes,
      "type": "textarea",
      "name": "notes",
      "human_name": "Notes",
      "required": false,
      "invalid_feedback": null
    }
  }

  const orgImageCropSettings = 20 / 9;

  return (
    <>
      <Header />
      <div className="container">
        <div className="row mt-2 mb-5">
          <div className="col-12 col-md-9">
            <div className="row">
              <OrganizationAdminHeader page_name='Organization Profile' />

              <div className="col-12" style={{color: 'teal'}}>
                <span className="fw-bold">Purpose: </span>
                <span className="fst-italic">Applying technology for the benefit of people and organizations.</span>
              </div>
              <div className="col-12" style={{color: 'teal'}}>
                <span className="fw-bold">Mission: </span>
                <span className="fst-italic">Add something here.</span>
              </div>
              <div className="col-12" style={{color: 'teal'}}>
                <span className="fw-bold">Vision: </span>
                <span className="fst-italic">2022</span>
              </div>
            </div>
          </div>

          <div className="col-9">
            <div className="card">
              <div className="card-body">
                <FormOrganizationDetails
                  data={data}
                  customColumns={formParsedValues}
                  colClass='col-12 col-md-4'
                  readonly={false}
                  setFormDatum={setFormDatum}
                  onSubmit={handleSubmitForm}
                />
              </div>
              <div className="card-footer bg-transparent d-flex flex-row-reverse">
                <button type="submit"
                    form="dataFormDetails"
                    className="btn btn-primary">Save
                </button>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="card">
              <div className="card-body">
                <h6 className="card-subtitle text-muted">Organization Image</h6>
                {
                  user_organizations_obj.isLoading ? <Loading /> :
                    <ImageUploader
                      src={organization.image !== null ? publicUrl(organization.image) : 'https://cepese-polo.info/wp-content/plugins/ldd-directory-lite/public/images/noimage.png'}
                      readonly={false}
                      setTempImgUrl={setTempOrgImg}
                      aspect={orgImageCropSettings} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
