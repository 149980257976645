import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {DatumAccordionItem} from "./DatumAccordionItem";
import {Accordion} from "react-bootstrap";

export const _SubDatum = ({datum, clickEditItem, clickAddComment, clickEditComment, clickRemoveComment, clickDeleteItem, handleChangeListOrder, handleOpenReminder, clickNewItem, readOnly, subdatumModalOpenKey, handleArchiveItem, handleMarKNotificationsAsRead, handleMarkPrivateItem,
                              handleSelectItem}:any) => {
    return (
        <div className='mx-3'>
            <div className="col-12 p-0 border-right text-muted fw-bold">
                Sub-Items
                {
                    (!readOnly && typeof clickNewItem == 'function') &&
                    <i title='Add Sub Item' className='mx-1 color-teal clickable badge bg-teal p-1'
                    style={{height: 'fit-content'}}
                       onClick={() => {clickNewItem(datum.type1, datum.id)}}><FontAwesomeIcon icon={faPlus}/></i>
                }
            </div>

            <Accordion defaultActiveKey={subdatumModalOpenKey} alwaysOpen>
                {
                    datum?.sub_data != undefined && datum.sub_data.map((sub_datum:any) => (
                        <DatumAccordionItem eventKey={sub_datum.id.toString()}
                                            key={sub_datum.id}
                                            datum={sub_datum}
                                            total_coach_notes={datum.sub_data.length}
                                            handleEditItem={clickEditItem}
                                            handleAddComment={clickAddComment}
                                            handleEditComment={clickEditComment}
                                            clickRemoveComment={clickRemoveComment}
                                            handleDeleteItem={clickDeleteItem}
                                            handleChangeListOrder={handleChangeListOrder}
                                            handleMarkPrivateItem={handleMarkPrivateItem}
                                            handleOpenReminder={handleOpenReminder}
                                            handleMarKNotificationsAsRead={handleMarKNotificationsAsRead}
                                            handleArchiveItem={handleArchiveItem}
                                            isSubItem={true}
                                            readOnly={readOnly}
                                            handleSelectItem={handleSelectItem}
                        />
                    ))
                }
            </Accordion>

            <h5 className='text-muted mt-2'>Archived Sub-Items</h5>
                {
                    (datum?.sub_data_archived != undefined && datum.sub_data_archived.length > 0) ?
                        <Accordion defaultActiveKey={subdatumModalOpenKey} alwaysOpen>
                            {
                                datum.sub_data_archived.map((sub_datum:any) => (
                                    <DatumAccordionItem eventKey={sub_datum.id.toString()}
                                                        key={sub_datum.id}
                                                        datum={sub_datum}
                                                        total_coach_notes={datum.sub_data.length}
                                                        handleActiveItem={handleArchiveItem}
                                                        isSubItem={true}
                                                        readOnly={readOnly}
                                    />
                                ))
                            }
                        </Accordion>
                    :
                    <span className='text-muted'>No Archived Sub-Items</span>
                }

        </div>
    )
}
