import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useForm} from "../../../hooks/useForm";
import {getRequest} from "../../../../helpers/getRequest";
import {zynity_api} from "../../../../config/external-routes";
import axios from "axios";
import Swal from "sweetalert2";
import {Header} from "../../../header/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {Loading} from "../../../ui/loading/Loading";
import {FormOrganizationDetails} from "./FormOrganizationDetails";

export const OrganizationDetails = ({readOnly = true}) => {
  const {organization_id} = useParams()
  const authenticated_user = useSelector(state => state.auth)
  const [readonly, setReadonly] = useState(readOnly)
  const [loading, setLoading] = useState(true)
  const [data, setFormDatum, setData] = useForm({})

  useEffect(() => {
    getRequest(`${zynity_api.admin_organizations}/${organization_id}`, authenticated_user.access_token).then((response) => {
      setData(response)
      setLoading(false)
    })
  }, [])

  const handleSubmitForm = (event) => {
    event.preventDefault();

    console.log("Going to validate")
    let form = document.getElementById('dataFormDetails');
    if (!form.checkValidity()) {
      console.log("Invalid Form")
      form.classList.add('was-validated');
      return false;
    }

    axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}
    axios.put(`${zynity_api.admin_organizations}/${organization_id}`, data)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
        })
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'We have problems updating the Organization!',
        })
      })
  }

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 user-main-tittle">
            <h4>
              Organization Details
            </h4>
          </div>
        </div>

        <div className="row mt-4 mb-4 d-flex justify-content-center">
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="card-title row">
                  <div className="col-6">
                    <h5 className="card-title">Service</h5>
                  </div>
                  {
                    readonly &&
                    <div className="col-6 text-end">
                      <FontAwesomeIcon onClick={() => setReadonly(false)}
                               title="Edit" icon={faPen}
                               style={{
                                 fontSize: "16px",
                                 color: "#4c4c4c",
                                 cursor: "pointer"
                               }}
                      />
                    </div>
                  }
                </div>
                {
                  loading ?
                    (<Loading />)
                    :
                    <FormOrganizationDetails
                      data={data}
                      readonly={readonly}
                      setFormDatum={setFormDatum}
                      onSubmit={handleSubmitForm}
                    />
                }
              </div>
              {
                readonly === false &&
                <div className="card-footer bg-transparent d-flex flex-row-reverse">
                  <button type="submit"
                      form="dataFormDetails"
                      className="btn btn-primary">Update
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
