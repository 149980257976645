import React from 'react'
import './loading.scss';

export const Loading = ({ centerPage = false }) => {
  return (
    <>
      {
        centerPage == true ?
          <div className="row loading-center-fixed">
            <div className="col-12">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          :
          <div className="d-flex justify-content-center my-3">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>

      }
    </>

  )
}
