import React, {useEffect, useState} from 'react'
import {Header} from "../../header/Header";
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import FilterComponent from "../../ui/FilterComponent";
import {ActionDropdown} from "../ActionDropdown";
import DataTable from "react-data-table-component";

export const AssessmentsManage = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(15);
	const [filterText, setFilterText] = React.useState(null);

	const fetchData = async page => {
		setLoading(true);
		setCurrentPage(page);
		let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
		const response = await axios.get(`${zynity_api.admin_assessments}?page=${page}&limit=${perPage}${filter}`);

		setData(response.data.data);
		setTotalRows(response.data.total);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchData(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		setCurrentPage(page);
		let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
		const response = await axios.get(`${zynity_api.admin_assessments}?page=${page}&limit=${newPerPage}${filter}`);

		setData(response.data.data);
		setPerPage(newPerPage);
		setLoading(false);
	};

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			setFilterText('');
		};

		const handleFilter = (e) => {
			setFilterText(e.target.value)
		}

		return (
			<FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText]);

	const columns = [
		{
			name: 'ID',
			selector: row => row.id,
			width: '50px'
		},
		{
			name: 'SKU',
			selector: row => row.SKU,
			width: '80px'
		},
		{
			name: 'Name',
			selector: row => row.Name,
			maxWidth: '200px'
		},
		{
			name: 'Fee',
			selector: row => row.Fee,
			width: '80px'
		},
		/*
		{
			name: 'Basis',
			selector: row => row.Basis,
			maxWidth: '200px'
		},
		*/
		{
			name: 'Member Fee',
			selector: row => row.FeeMember,
			maxWidth: '10px'
		},
		{
			name: 'Trial',
			selector: row => row.TrialPeriod,
			width: '60px'
		},
		{
			name: 'Description',
			selector: row => row?.Description != null ? row.Description.replace(/<\/?[^>]+(>|$)/g, "") : '',
			maxWidth: '200px'
		},
		{
			name: 'Actions',
			cell: row => <ActionDropdown
				linkView={`/admin/assessments/${row.id}`}
				linkEdit={`/admin/assessments-update/${row.id}`}
				linkDelete={`${zynity_api.admin_assessments}/${row.id}`}
				messageBeforeToDelete={`Do you want to delete the service: ${row.Name} (ID: ${row.id})?`}
				fetchData={fetchData}
				currentPage={currentPage} />,
			allowOverflow: true,
			button: true

		}
	];

	useEffect(() => {
		fetchData(1)
	}, []);

	useEffect(() => {
		if(filterText !== null) {
			const timeOutId = setTimeout(() => fetchData(1), 500);
			return () => clearTimeout(timeOutId);
		}
	}, [filterText]);

	return (
		<>
			<Header />
			<div className="container">
				<div className="row">
					<div className="col-8 user-main-tittle">
						<h4>
							View / Manage Assessments
						</h4>
					</div>
					<div className="col-4 mt-3">
					  &nbsp; &nbsp; <a href="/admin/z-admin">Admin Home</a> 
					</div>
				</div>

				<div className="row">
					<div className="col-12 p-5">
						<DataTable
							columns={columns}
							data={data}
							progressPending={loading}
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							paginationPerPage={perPage}
							subHeader
							subHeaderComponent={subHeaderComponentMemo}
						/>
					</div>
				</div>
			</div>
		</>
	)
}
